

import React, { useState, useEffect } from 'react';
import sites from '../description.json';
import search_data from './imported/search-result.json';

// HTML inject imports
// TODO: Lazy Load these
import DatasetPage from './imported/braket.ai/DatasetPage.jsx';
import DatasetSearchCard from './imported/braket.ai/DatasetSearchCard.jsx';



const Comments = function(props) {
	let html=[];
	let comm = sites[props.site]["tabs"][props.tab]["comments"];
	Object.entries(comm).forEach( (x) => html.push(<div className={"comm-"+props.site}><p>{x[1]["text"]}</p></div>));
	return <div className="comments">{html}</div>;
}

const MobileLinks = function(props) {
	return(
		<div className="mobile_links">
			<div className="expanded_mobile_link" onClick={() => window.open(props.git, '_blank')}>
				<img src="./assets/github-link_white.svg" alt="github link" />
			</div>
			<div className="expanded_mobile_link mobile_link_second" onClick={() => window.open("https://"+props.link, '_blank')}>
				<img src="./assets/live-link_white.svg" alt="live website link" />
			</div>
		</div>
	);
}

function compute_comm_depth() {
	var depths = []; 
	var current_depth = 0;
}

function get_HTML(site, tab) {
	switch(site) {
		case "opendataset":
				if(tab=="0") {
					const ui = search_data.results.map( (x) => (
							<DatasetSearchCard
									name={x.name}
									description={x.description}
									data={x.data}
									snippet={x.snippet}
									licence={x.licence}
									tags={x.tags}
									score={x.score}
								/>
					));
					return(
						<div className="braket-ai"> 
							<h3 className="comment_title"> Quick View </h3>
							<p className="comment">View of dataset as it would appear in searches. It tries to maximise the availability of critical information to the user in order to quickly filter through multiple instances and decide whether the data would be of use or not.</p>
							{ui[1]}
							<h3 className="comment_title"> Dataset Presentation Page </h3>
							<p className="comment">General use presentation page for all datasets. This is where most of the time will be spent on the website. It allows the user to purchase access to the dataset, download the file or copy the API link. Users can raise issues, join discussions or share example code that makes use of the data.</p>
							<DatasetPage title="" description="" />
						</div>
					);
				}
				break;
		case "personalwiki":
				if(tab=="0") {
					return (
						<React.Fragment>
							<div className="braket-ai">
								<h3 className="comment_title">Document View</h3> 
								<p className="comment">Structured document of cells rendering LaTeX and Markdown:</p>
							</div>
							<div className="personalwiki">
								<img src="./assets/personalwiki.gif" alt="personalwiki cell working" />
							</div>
						</React.Fragment>
					);	
				}
				break;
		default:
			throw "ProjectCard / HTML : Invalid HTML inject target." + site;
	}
}

const Tab = function(props) {
	var html;
	var b_alltext = false;
	var b_mobile = false;
	if(props.resolution === 1) b_alltext = true;
	else if (props.resolution === 2) b_mobile = true;

	if(props.type=="UI") useEffect( () => compute_comm_depth() )
	if(props.type=="HTML") html = get_HTML(props.tag, props.tab);

	if(props.type === "UI" && !b_alltext && !b_mobile) {
		return(
			<div className="expanded_images">
				<Comments site={props.tag} tab={props.tab} />
				<img src={sites[props.tag]["tabs"][props.tab]["desktop"]} 
						 alt={ "Desktop " + props.tag } className="desktop-image" />
				<img src={sites[props.tag]["tabs"][props.tab]["mobile"]}
						 alt={ "Mobile " + props.tag } className="mobile-image" />
			</div>
		);
	} else if (props.type === "HTML" && !b_alltext && !b_mobile) {
		return(
			<div className="expanded_html">
				{get_HTML(props.tag, props.tab)}
			</div>
		);
	} else if (props.type === "TEXT" || b_alltext || b_mobile) {
		var pars = [];
		for(let i=0; i<sites[props.tag]["tabs"][props.tab]["parnum"]; i++) {
			pars.push(<p className="text_par">{sites[props.tag]["tabs"][props.tab]["text"][i]}</p>);
		}
		return(
			<React.Fragment>
				{ pars }
				{ props.resolution == 2 ? <MobileLinks git={props.git} link={props.link} /> : null }	
			</React.Fragment>
		);
	}
}

class TabManager extends React.Component {
	constructor(props) {
		super(props);
		this.state = { tab: '0'};
		this.generate_buttons = this.generate_buttons.bind(this);
	}

	generate_buttons() {
		var buttons = [];
		const tabs = sites[this.props.tag]["tabs"];
		Object.keys(tabs).forEach( (x) => buttons.push( <p onClick={() => this.change_tab(x)} className={(parseInt(x)==this.state.tab) ? "tab-selected" : null} > {tabs[x]["name"]} </p>));
		return (buttons.length > 1) ? buttons : "";
	}
	
	change_tab(ntab) { this.setState({tab: ntab}); }

	/*
		Resolution can be:
		0 : Normal Screen ( X > 1200)
		1 : Narrow Screen ( 700 < X < 1200)
		2 : Mobile Screen ( X < 700 )
	*/
	render() {
		return(
			<div className={ this.props.resolution == 2 ? "expanded_restrain__mobile" : "expanded_restrain"}>
				<div className={this.props.resolution == 2 ? "expanded_about__mobilie" : "expanded_about"}>
        	<a href={sites[this.props.tag]['source']} target='_blank'><h3>{sites[this.props.tag]["name"]}</h3></a>
					<p>{sites[this.props.tag]["description"]}</p>
				</div>
				<div className="tab-selector">
					<div>
					{ this.props.resolution == 0 ? this.generate_buttons() : null }
					</div>
				</div>
				<Tab type={sites[this.props.tag]["tabs"][this.state.tab]["type"]} tag={this.props.tag} 
					  tab={this.state.tab} link={sites[this.props.tag]["source"]} git={sites[this.props.tag]["source"]}
					  resolution={this.props.resolution} />
			</div>
		);
	}
}

export default TabManager;
