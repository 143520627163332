import React from "react";
import TabManager from "./Tabnew.jsx";

class ProjectCard extends React.Component {
  constructor(props) {
    super(props);

	/*
		Resolution can be:
		0 : Normal Screen ( X > 1200)
		1 : Narrow Screen ( 700 < X < 1200)
		2 : Mobile Screen ( X < 700 )
	*/
    this.state = {
      expand: false,
      pending_closure: false,
      res: 2,
      html_res: 0,
    };
    this.expand = this.expand.bind(this);
    this.move_close_button = this.move_close_button.bind(this);
    this.set_responsive = this.set_responsive.bind(this);
    this.set_expand = this.set_expand.bind(this);

    this.closeButton = React.createRef();
  }

  set_expand(new_state) {
    this.setState({ expand: new_state });
  }

  set_responsive(new_state) {
    this.setState({ res: new_state });
  }

	change_responsive_states(width) {
    if (width < 700) {
			this.set_responsive(2);
 			this.setState({ html_res: 2 });
		}
    else if (width < 1200) this.set_responsive(1);
    else if (width > 1200) this.set_responsive(0);

    if (width < 1500) this.setState({ html_res: 1 });
    else if (width > 1500) this.setState({ html_res: 0 });
	}

  componentDidMount() {
		this.change_responsive_states(document.body.clientWidth);
    window.addEventListener( "resize", (event) => { this.change_responsive_states(document.body.clientWidth); }, true,);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.move_close_button);
  }

  move_close_button() {
    if (this.state.expand) {
      // Make use of React Refs to link to this project's close button
      var parent = this.closeButton.current.parentElement;
      const start = parent.offsetTop - window.innerHeight / 2 + 50;
      const stop =  parent.clientHeight + parent.offsetTop - window.innerHeight / 2 - 70;
      const scrolltop = window.pageYOffset;

      // Start tracking
      if (scrolltop > start && scrolltop < stop) {
        this.closeButton.current.style.transition = "0s";
        this.closeButton.current.style.top = "49vh";
        this.closeButton.current.style.position = "fixed";

        // Stop tracking
      } else if (scrolltop > stop) {
        this.closeButton.current.style.position = "absolute";
        this.closeButton.current.style.top = "auto";
        this.closeButton.current.style.bottom = "20px";

        // If you go up again
      } else if (scrolltop < start) {
        this.closeButton.current.style.top = "50px";
        this.closeButton.current.style.position = "absolute";
      }
    }
  }

  close_expanded_project() {
    const destination = this.closeButton.current.parentElement.offsetTop - window.innerHeight / 2 + 50;
    window.scrollTo({ top: destination });
    this.set_expand(false);
  }

  /* Expand or collapse the project card */
  expand(option) {

    // X > 1200
		console.log(this.state.res)
    if (option && this.state.res === 0) {
      window.addEventListener("scroll", this.move_close_button);
      return (
        <article className="project_expanded">
          <div className="expanded_close" onClick={() => this.close_expanded_project()} ref={this.closeButton} >
            <img src="./js/imported/braket.ai/assets/close.svg" alt="exit button" />
          </div>
          <TabManager tag={ this.props.tag } resolution={0} />
        </article>
      );

      // 700 < X < 1200 
    } else if (option && this.state.res === 1) {
      window.addEventListener("scroll", this.move_close_button);
      return (
        <article className="project_expanded">
          <div className="expanded_close" onClick={() => this.close_expanded_project()} ref={this.closeButton} >
            <img src="./js/imported/braket.ai/assets/close.svg" alt="exit button" />
          </div>
          <TabManager tag={ this.props.tag } resolution={1} />
        </article>
      );

      // X < 700
    } else if (option && this.state.res === 2) {
      return (
        <article className="project_expanded__mobile">
          <TabManager tag={ this.props.tag } resolution={2} />
        </article>
      );

      // Normal Collapsed Projects
    } else if (this.state.res == 0 || this.state.res == 1) {
      // The extra div is because of some wierd fallthrough of style attributes
      // The attributes of .expanded-close fall to the first div of the main div.
      return (
        <article className="project">
					<div></div>
          <div className="project-container" onClick={() => this.set_expand(!this.state.expand)} >
          	<h2> {this.props.title} </h2>
            <h3> {this.props.tech} </h3>
            <p> {this.props.description} </p>
          </div>
          <div className="project-links">
            <div className="project_link" onClick={() => { window.open(this.props.git, "_blank"); }} >
              <img src="./assets/github-link_white.svg" alt="button to github repo of project" />
            </div>
            <div className={ this.props.link != null && !this.props.nolink
                  ? "project_link project_link_second"
                  : "project_link__unavailable project_link_second" }
              onClick={ this.props.link != null ? () => window.open(this.props.link, "_blank") : null }
            >
              <img src="./assets/live-link_white.svg" alt="button to live website of project" />
            </div>
          </div>
        </article>
      );

      // Mobile Collapsed Projects
    } else {
      return (
        <article className="project-container__mobile project-container" onClick={() => this.set_expand(!this.state.expand)} >
					<div>
          	<h2> {this.props.title} </h2>
          	<h3> {this.props.tech} </h3>
					</div>
          <img src="./assets/more-white.svg" alt="expand project" />
        </article>
      );
    }
  }

  render() {
    return <React.Fragment>{this.expand(this.state.expand)}</React.Fragment>;
  }
}

export default ProjectCard;
