import React from "react"; import { useState } from "react";
import { render } from "react-dom";
import ProjectCard from "./Project.jsx";
import ContactForm from "./Contact.jsx";

// Present this website in console
console.log(`
      _._     _,-'""\-.
     (,-.\`.,'(       |\`-/|
         \`-.-' \ )-\`( , o o)
               \`-    \`_\`"'-`);

console.log("\n\nLastly, there is this website, built in React.js with JSX.");
console.log("Find the code at: https://github.com/GandalfTea/web_portfolio");
console.log(".............................................................");

const TimeEntry = function (props) {
  return (
    <p>
      <strong>
        {props.start} {props.until != "" ? " - " + props.until + ": " : ": "}
        {props.title}
      </strong>
      {props.description}
    </p>
  );
};

const Projects = function () {
  const [open, setOpen] = useState(false);
  var button_src = "./assets/expand_arrow.svg";
  var idx = 0;
  if (!open) {
    return (
      <div className="projects">
        <h2>Projects</h2>

        <ProjectCard
          title="OpenDataset: Crowd-sourcing dataset creation"
          tech="Express.js, React.js, React Router, PostgreSQL, TS, Pug"
          type="html"
          tag="opendataset"
          filename="DatasetPage.jsx"
          git="https://github.com/GandalfTea/opendataset"
          description="Webserver managing user sessions, JSON or CSV contributions, continuous integration user-defined testing, etc. Advanced cacheing and logging."
          idx={idx++}
        />

        <ProjectCard
          title="PersonalWiki: scripting and note-taking"
          tech="Django, Express.js, TypeScript, React.js, REST"
          type="video"
          video="../assets/personalwiki.mp4"
          tag="personalwiki"
          git="https://github.com/GandalfTea/personalwiki"
          description="Cell-based UI that renders Markdown and LaTeX. C/C++ runtime in the works."
          idx={idx++}
        />

        <ProjectCard
          title="stejar.casa - real estate presentation"
          tech="Three.js, jQuery, Google Maps API, LazyLoad"
          tag="stejar.casa"
          type="UI"
          git="https://github.com/GandalfTea/stejar.casa"
					link="https://stejar.casa"
          description="Duplex presentation done with 3D models, interactive image viewers, etc."
          idx={idx++}
        />

        <button
          className="projects_expand_button"
          onClick={() => setOpen(true)}
          aria-label="expand"
        > <img src="./assets/more.svg" alt="close expanded projects" /> </button>
      </div>
    );
  } else {
    return (
      <div className="projects">
        <h2>Projects</h2>

        <ProjectCard
          title="OpenDataset: Crowd-sourcing dataset creation"
          tech="Express.js, React.js, React Router, PostgreSQL, TS, Pug"
          type="html"
          tag="opendataset"
          filename="DatasetPage.jsx"
          git="https://github.com/GandalfTea/opendataset"
          description="Webserver managing user sessions, JSON or CSV contributions, continuous integration user-defined testing, etc. Advanced cacheing and logging."
          idx={idx++}
        />

        <ProjectCard
          title="PersonalWiki: scripting and note-taking"
          tech="Django, Express.js, TypeScript, React.js, REST"
          type="video"
          video="../assets/personalwiki.mp4"
          tag="personalwiki"
          git="https://github.com/GandalfTea/personalwiki"
          description="Cell-based UI that renders Markdown and LaTeX. C/C++ runtime in the works."
          idx={idx++}
        />

        <ProjectCard
          title="stejar.casa - real estate presentation"
          tech="Three.js, jQuery, Google Maps API, LazyLoad"
          tag="stejar.casa"
          type="UI"
          git="https://github.com/GandalfTea/stejar.casa"
					link="https://stejar.casa"
          description="Duplex presentation done with 3D models, interactive image viewers, etc."
          idx={idx++}
        />

        <ProjectCard
          title="claritatea.ro - web magazine"
          tech="jQuery, REST, Encryption, XD"
          tag="claritatea.netlify.app/"
          type="UI"
          git="https://github.com/GandalfTea/claritatea.ro"
					link="https://claritatea.netlify.app/"
          description="Showcasing the latest issue and keeping contributors updated"
          idx={idx++}
        />

        <ProjectCard
          title="Pristine Houses: Web design comission"
          tech="Adobe XD"
          tag="pristinehouses.ca"
          type="UI"
          description="Freelance commision web design done for local business."
          idx={idx++}
        />

        <button
          className="projects_expand_button"
          onClick={() => setOpen(false)}
          aria-label="expand"
        >
          <img src="./assets/collapse_arrow.svg" alt="expand button" />
        </button>
      </div>
    );
  }
};

const PersonalProjects = function () {
  return (
    <div className="extra-projects">
      <h3>Personal Projects</h3>
      <ProjectCard
        title="Tensorlib: general-purpose tensor library."
        tech="C++, C, AVX, CUDA"
        type="text"
        tag="tensorlib"
        git="https://github.com/GandalfTea/MRCV"
        description="General use header-only tensor library. Similar interface to pytorch or tinygrad."
      />
      <ProjectCard
        title="SLAM with Extended Kalman Filter with Symbolic Jacobian Computation"
				title="SLAM with EKF"
        tech="C++, Eigen, OpenCV, Pangolin"
        type="text"
        tag="SLAM"
        git="https://github.com/GandalfTea/MRCV"
        description="Simultaneous Localisation and Mapping algorithm with an extended kalman filter. It creates a 3D environment map from one or more cameras."
      />

      <ProjectCard
        title="Game Engine with Procedurally Generated Terrain"
        tech="C++, OpenGL, GLUT"
        type="text"
        tag="Engine"
        git="https://github.com/GandalfTea/enginehmw"
        description="Game Engine C++ implementation from scratch with Perlin Noise procedurally generated terrain, .OBJ file loader, model viewer, collision detection, primitive generation, etc."
      />
    </div>
  );
};

const Paintings = function () {
  return (
    <div className="paintings">
      <h3>Paintings and Illustrations</h3>
      <img
        src="./assets/painting-2.jpg"
        alt="portfolio painting"
        className="painting"
      />
      <img
        src="./assets/painting-1.jpg"
        alt="portfolio painting"
        className="painting"
      />
      <img
        src="./assets/painting-3.jpg"
        alt="portfolio painting"
        className="painting"
      />
      <img
        src="./assets/painting-4.jpg"
        alt="portfolio painting"
        className="painting"
      />
    </div>
  );
};

class Page extends React.Component {
  constructor(props) {
    super(props);
    this.state = { extra: false };
    this.project_state = {
      "braket.ai": false,
      "claritatea.netlify.app/": false,
      "stejar.casa": false,
      "louisgeorge.me": false,
      "lists.directory": false,
    };
  }

  render() {
    var button_src = "./assets/more.svg";
    if (this.state.extra) button_src = "./assets/collapse_arrow.svg";

    return (
      <div className="paging">
        <ContactForm />
        <div className="intro">
          <h1 className="intro_name">
            <a href="https://github.com/GandalfTea" target="_blank" aria-label="GitHub" > Octavian Rusu </a>
          </h1>
          <h3 className="intro_title"> <b>Web Developer</b> </h3>
          <img src="./assets/drawing.webp" className="intro_image" alt="introduction painting" />
          <main> <h2 className="intro__des_title">TLDR</h2>
            <ul>
              <li>
                Actively building <a target="_blank" href="https://github.com/GandalfTea/opendataset">opendataset</a>
                , a git-inspired server that enables collaborative dataset creation 
                with a user-friendly React interface and <a target="_blank" href="https://github.com/GandalfTea/personalwiki">personalwiki</a>
                , a browser tool for notes and scripting, soon with a C++17 runtime.
              </li>
              <li> Working freelance, designed and built <a target="_blank" href="https://stejar.casa">stejar.casa </a> 
							and <a target="_blank" href="https://claritatea.netlify.app/">claritatea.ro</a>.  </li>
							<li> Experience in web design, magazine layout and poster design.</li>
						{ /*<li> Actively doing commission <a href="">pencil-based web illustrations</a>.  </li> */}
							<li> 
                Actively working on a <a target="_blank" href="https://github.com/GandalfTea">ARM training and inference lib</a> in C/C++ for
                low-power electronics with a focus on satellites and a <a target="_blank" href="https://github.com/GandalfTea/tensorlib">AVX & CUDA C++17 tensor lib</a>. 
								Competent in statistical learning and data modeling, PyTorch, Numpy, Pandas.
								Built a <a target="_blank" href="https://github.com/GandalfTea/market_sim"> financial market simulator</a>.
							</li>
							<li>
								Built a <a target="_blank" href="https://en.wikipedia.org/wiki/Simultaneous_localization_and_mapping">SLAM</a> <a target="_blank" href="https://github.com/GandalfTea/MRCV">lib </a> 
								for DIY <a target="_blank" href="https://en.wikipedia.org/wiki/Head-mounted_display">HMD</a>s odometry tracking and self-driving models.
								Limited experience in computer graphics, built a <a target="_blank" href="https://github.com/GandalfTea/enginehmw">game engine</a>.
							</li>
              <li> <a href="mailto: contact@octavian.work"><i>contact@octavian.work</i></a> </li>
            </ul>
          </main>
        </div>
        <Projects />
        <div className="experience">
          <h3>Work Experience</h3>
          <TimeEntry
            start="2023"
						until=""
            title="Freelance Web Design"
            description=", for local business Pristine Houses"
          />
          <TimeEntry
            start="2019"
            until="2020"
            title="Graphic Designer and Web Developer"
            description=", Octav Bancila Highschool, designed and built the magazine website claritatea.ro, designed two volumes of the Claritatea magazine and one volume of the ArtEast magazine."
          />
          <TimeEntry
            start="2018"
            until="2019"
            title="Graphic Designer"
            description=" for the Erasmus+ program My City, My Home, My Responsibility"
          />
        </div>
        <div className="education">
          <h3>Education</h3>
          <TimeEntry
            start="2020"
            until="2023"
            title="Virtual and Augmented Reality Development"
            description=" BSc (Hons) at the University of Portsmouth, UK"
          />
          <p>Completed courses such as:</p>
          <ul>
            <li>UX / UI</li>
            <li>
              Mobile Application Development ({" "}
              <a
                href="https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps"
                target="_blank"
                aria-label="link to wiki"
              > PWAs </a>
              )
            </li>
            <li>
              <a
                href="https://en.wikipedia.org/wiki/Extended_reality"
                target="_blank"
                aria-label="Wiki"
              > XR</a> Development (convnet facial and hand tracking,
              <a
                href="https://en.wikipedia.org/wiki/Simultaneous_localization_and_mapping"
                target="_blank"
                aria-label="link to wiki"
              > SLAM </a> ,
              <a
                href="https://immersiveweb.dev/"
                target="_blank"
                aria-label="link to wiki"
              > WebXR </a> ,
              <a
                href="https://www.unrealengine.com/en-US"
                target="_blank"
                aria-label="link to UE4 website"
              > UE4 </a>)
            </li>
						<li>
							Programming (
							<a
								href="https://en.wikipedia.org/wiki/Structure_and_Interpretation_of_Computer_Programs"
								target="_blank"
								aria-label="link to textbook"
							>SICP</a>,  
							<a
								href="https://en.wikipedia.org/wiki/Lisp_(programming_language)"
								target="_blank"
								aria-label="link to wiki"
							>LISP</a>,  
							<a href="https://en.wikipedia.org/wiki/Field-programmable_gate_array"
								target="_blank"
								aria-label="link to wiki"
							>FPGA</a>s, x86 C and Assembly, 
							<a 
								href="https://beej.us/guide/bgnet/html/split/"
								target="_blank"
								aria-label="link to blog"
							>Networking</a>, etc.)
						</li>
            <li>
              Math (multiple view geometry, tensor arithmetic, physics simulation, etc.)
            </li>
            <li>3D Modeling and Animation (in Maya, 3DS Max and Blender)</li>
            <li>Agile Methodologies</li>
          </ul>
        </div>
        <PersonalProjects />
        {/*this.state.extra ? <Voluntary /> : null*/}
        {this.state.extra ? <Paintings /> : null}
        <button
          className="projects_expand_button"
          onClick={() =>
            this.state.extra
              ? this.setState({ extra: false })
              : this.setState({ extra: true })
          }
          aria-label="expand"
        >
          <img src={button_src} alt="more information" />
        </button>
        <p className="footer">August 2023</p>
      </div>
    );
  }
}

render(<Page />, document.getElementById("page"));
